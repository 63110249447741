/*
    ONLY GENERAL STYLE DEFINITIONS GO HERE, ALL OTHER DEFINITIONS GO TO Theme.js
*/
html {
    font-size: "12px";
    height: 100%;
}
body {
    height: 100%;
	color: #656565;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: normal;
	font-style: normal;
    line-height: 1;
    overflow: hidden;
}
body :focus, body :active { outline: 0; border: 0; } ::-moz-focus-inner { border: 0; }
div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, p, blockquote, th, td {
	margin: 0;
	padding: 0;
}
p {
	font-family: inherit;
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.6;
	margin-bottom: 1.25rem;
	text-rendering: optimizeLegibility;
}
#root {
    height: 100%;
}
#description {
    white-space: pre-line;
    margin-left: 0.5rem;
}
/* Special container-main definition which is only used in ContainerPage because it utilizes the calc function of CSS */
.container-main {
    height: calc(100vh - 69px);
    overflow-y: scroll;
}
/* Special for holidays calendar */
.calendar {
    overflow: hidden;
}